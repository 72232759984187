<template>
  <div class="p-1">
    <div class="mb-4 flex justify-between items-start">
      <span class="flex items-center">
        <a-button @click="$router.back()" class="mr-2">
          Regresar
        </a-button>
        <span v-if="enabledPeriod.loading" class="flex items-center">
          <a-loader class="mr-2 h-5 w-5 inline-block" /> Buscando período actual
        </span>
      </span>
      <a-dropdown v-if="$route.name === 'student' && !user.isCoordinator" :title="`Ver opciones ${ student ? `para ${student.first_name}` : ''}`">
        <template #menu>
          <a-dropdown-item
            v-if="student && user.isAdmin && $route.name === 'student'"
            :to="{ name: 'edit_students', params: { student: student.id } }">
            Actualizar
          </a-dropdown-item>
          <a-dropdown-item
            v-if="user.isAdmin && $route.name === 'student'"
            @click="updateEmailModal = true">
            Actualizar correo
          </a-dropdown-item>
          <a-dropdown-item
            @click="reinscript(student)"
            v-if="shouldShowInscriptionButton">
            {{ enabledPeriod.loading ? 'Buscando periodo actual' : 'Inscribir' }}
          </a-dropdown-item>
          <a-dropdown-item
            @click="changeSpecialization(student)"
            v-if="shouldShowChangeSpecializationButton">
            {{ enabledPeriod.loading ? 'Buscando periodo actual' : 'Cambiar de especialización' }}
          </a-dropdown-item>
          <a-dropdown-item
            @click="sendEmailModal = true"
            v-if="user.isAdmin && $route.name === 'student'">
            Enviar correo de confirmación
          </a-dropdown-item>
          <a-dropdown-item v-if="user.isAdmin && $route.name === 'student'" @click="modals.passwordReset = true">
            Cambiar contraseña
          </a-dropdown-item>
          <a-dropdown-item
            target="_blank"
            v-if="student && (user.isAdmin || user.isLowAdmin) && $route.name === 'student'"
            :href="`${url}/api/students/${student.id}/reports/profile-info?token=${token}`">
            Imprimir datos
          </a-dropdown-item>
          <a-dropdown-item
              v-if="user.isAdmin"
              @click="retireStudentModal = true">
              {{ student.is_retired ? 'Activar estudiante' : 'Retirar estudiante' }}
          </a-dropdown-item>
            <a-dropdown-item
                v-if="user.isAdmin && $route.name === 'student' && student"
                @click="disabledUserModal = true">
                {{ getEnabling ? 'Deshabilitar estudiante' : 'Habilitar estudiante' }}
            </a-dropdown-item>
          <span
            v-if="!(student && (user.isAdmin || user.isLowAdmin) && $route.name === 'student') && !shouldShowInscriptionButton"
            class="inline-block py-2">
            No hay opciones disponibles
          </span>
        </template>
      </a-dropdown>
    </div>
    <div class="pb-4">
      <div class="rounded-xl bg-white flex xl:flex-nowrap flex-wrap">
        <nav class="xl:w-56 w-full flex-shrink-0 xl:rounded-l-xl rounded-t-xl xl:rounded-t-none overflow-hidden border-r border-gray-100">
          <ul>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink()" exact>
                Datos personales
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('history')" exact v-if="user.isAdminGroup">
                Historial académico
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('notifications')" exact>
                Notificaciones
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('binnacles')" exact>
                Bitácora
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('courses')" exact v-if="!user.isTeacher">
                Materias
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('lessons')" exact>
                Clases
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('unattendances')" exact>
                Inasistencias
              </router-link>
            </li>
            <li class="text-gray-700 hover:text-primary">
              <router-link class="py-2 px-2 flex" active-class="text-white bg-primary bg-opacity-75 hover:text-white" :to="studentsLink('delays')" exact>
                Retrasos
              </router-link>
            </li>
          </ul>
        </nav>
        <main class="flex-grow">
          <router-view />
        </main>
      </div>
    </div>

    <a-modal
        :show="modals.passwordReset"
        @cancel="modals.passwordReset = false"
        @confirm="resetPassword(student)"
        :title="'Nueva Contraseña'"
        :confirm-text="'Aceptar'"
    >
      <a-input type="password" v-model="new_password">Nueva contraseña</a-input>
    </a-modal>

    <a-modal
      :show="updateEmailModal"
      @cancel="updateEmailModal = false"
      @confirm="updateEmail"
      :confirm-text="'Aceptar'"
    >
      <h3>Actualizar Email</h3>
      <a-input required label="Email" v-model="newEmail"/>
    </a-modal>

    <a-modal
      :show="sendEmailModal"
      @cancel="sendEmailModal = false"
      @confirm="sendEmail(student)"
      :confirm-text="'Aceptar'"
    >
      <h3 class="text-center text-lg">¿Desea reenviar el correo de confirmación?</h3>
    </a-modal>
    <a-modal
      :disabled-confirm="isChangeSpecializationButtonDisabled === false"
      :show="changeSpecializationModal"
      @cancel="changeSpecializationModal = false; changeStudentSpecialization.specialization_id = null"
      @confirm="doChangeSpecialization()"
      :confirm-text="'Aceptar'"
    >
      <h3 class="text-center text-lg">Cambio de especialización</h3>
      <div class="flex flex-wrap -mx-2 text-left mt-4">   
        <div class="w-full px-2">
          <a-select
            required
            filterable
            :source="specializations"
            v-model="changeStudentSpecialization.target_specialization_id"
            label="Seleccione una especialización"
            id="specialization_id"
            :loading="loadingSpecializations"
            :disabled="loadingSpecializations"
            class="mt-4"
            v-if="shouldDisplaySpecializationSelect"/>
        </div>
        <div class="w-full px-2">
          <a-select
            required
            filterable
            :source="filteredPensum"
            v-model="changeStudentSpecialization.target_pensum_id"
            label="Seleccione un pensum"
            id="pensum_id"
            :loading="loadingSpecializations"
            :disabled="changeStudentSpecialization.target_specialization_id === null"
            class="mt-4"
            v-if="shouldDisplaySpecializationSelect"/>
        </div>
        <div class="w-full px-2">
          <a-select
            required
            filterable
            :source="filteredSemesters"
            v-model="changeStudentSpecialization.target_semester_id"
            label="Seleccione un semestre"
            id="semester_id"
            :loading="loadingSpecializations"
            :disabled="changeStudentSpecialization.target_specialization_id === null || changeStudentSpecialization.target_pensum_id === null"
            class="mt-4"
            v-if="shouldDisplaySpecializationSelect"/>
        </div>
        
      </div>
    </a-modal>

    <a-modal
      :show="disabledUserModal"
      @cancel="disabledUserModal = false"
      @confirm="disableUser(student)"
      :confirm-text="'Aceptar'"
    >
      <h3 class="text-center text-lg">¿Desea {{ getEnabling ? 'Deshabilitar' : 'Habilitar' }} este usuario?</h3>
    </a-modal>

    <a-modal
        v-if="student"
        :show="retireStudentModal"
        @cancel="retireStudentModal = false"
        @confirm="retireStudent"
        :confirm-text="'Aceptar'"
    >
      <h3 class="text-center text-lg">{{`¿Desea ${student.is_retired ? 'activar' : 'retirar'} este estudiante?`}}</h3>
    </a-modal>
    <a-modal
        v-if="student"
        :show="alertChangeSpecialization"
        @cancel="alertChangeSpecialization = false"
        @confirm="$router.push({ name: 'payments' })"
        :confirm-text="'Ir a pagos'"
    >
      <h3 class="text-center text-lg font-bold">{{`Recuerde que debe volver a aprobar el pago del estudiante.`}}</h3>
    </a-modal>

    <a-modal
      :disabled-confirm="isConfirmInscriptionButtonDisabled"
      :show="inscriptionModal"
      @cancel="inscriptionModal = !inscriptionModal"
      confirm-text="Confirmar"
      @confirm="doReinscription">
      <div v-if="student">
        <template v-if="passed">
          <h2 class="text-2xl">El estudiante puede avanzar al siguiente semestre</h2>
          <template v-if="isBasic">

            <h3 class="text-xl mt-2" v-if="inscription.specialization_id">
              Al presionar <b>"confirmar"</b>, el estudiante se estará inscribiendo en <b>{{ specializations ? specializations.find($0 => $0.value === inscription.specialization_id).label : '' }}</b>
            </h3>
            <template v-if="student.latest_inscription.semester.number === 2">
              <h3 class="text-xl mt-2 font-bold">
                Para avanzar al tercer semestre se debe seleccionar una especialidad
              </h3>

              <a-select
                :source="specializations"
                v-model="inscription.specialization_id"
                :loading="loadingSpecializations"
                :disabled="loadingSpecializations"
                class="mt-4"
                v-if="shouldDisplaySpecializationSelect"/>

              <template v-if="specializationCourses.length">
                <h3 class="my-2 font-bold">Estas son las materias que el estudiante verá</h3>
                {{ specializationCourses.map($0 => $0.title).join(', ') }}
              </template>
            </template>
            <template v-else>
              <!-- <h3 class="my-2 font-bold">El estudiante cursar</h3> -->
              <!-- {{ nextCourses.map($0 => $0.title).join(', ') }} -->
            </template>

          </template>
          <template v-else-if="failedCourses.length != 0">
            <h3 class="text-xl mt-2">
              Al presionar <b>"confirmar"</b>, el estudiante se estará inscribiendo en el <b>semestre {{ student.latest_inscription.semester.number + 1 }}</b> de <b>{{ currentSpecialization.title }}</b> con materias del <b>semestre {{ student.latest_inscription.semester.number}}</b>
            </h3>
            <h3 class="text-xl my-2">Estas son las materias que el estudiante verá</h3>
            <h2 class="my-2 font-bold text-red-500">Materias del semestre {{ student.latest_inscription.semester.number }}</h2>
            {{ failedCourses.map($0 => $0.label).join(', ') }}
            <h2 class="my-2 font-bold">Materias del semestre {{ student.latest_inscription.semester.number + 1 }}</h2>
<!--            {{ loadingSpecializations-->
<!--            ? 'Cargando...'-->
<!--            : !specializationCourses.length-->
<!--              ? '&#45;&#45; No hay siguientes materias'-->
<!--              : specializationCourses.map($0 => $0.title).join(', ')-->
<!--            }}-->
            {{ nextCourses.map($0 => $0.title).join(', ') }}
          </template>
          <template v-else>
            <h3 class="text-xl mt-2">
              Al presionar <b>"confirmar"</b> el estudiante se estará inscribiendo en el <b>semestre {{ student.latest_inscription.semester.number + 1 }}</b> de <b>{{ currentSpecialization.title }}</b>
            </h3>
            <h3 class="my-2 font-bold">Estas son las materias que el estudiante verá</h3>
<!--            {{ loadingSpecializations-->
<!--            ? 'Cargando...'-->
<!--            : !specializationCourses.length-->
<!--              ? '&#45;&#45; No hay siguientes materias'-->
<!--              : specializationCourses.map($0 => $0.title).join(', ')-->
<!--            }}-->
            {{ nextCourses.map($0 => $0.title).join(', ') }}
          </template>
        </template>
        <template v-else>
          <template>
            <h2 class="text-2xl text-center mb-4 text-red-500">Este estudiante no ha conseguido aprobar</h2>
<!--            <h2 class="text-xl text-center mb-4">¿Repetir el semestre {{ student.latest_inscription }} en Básica?</h2>-->
<!--            <h3 class="text-lg my-2 text-gray-800">-->
<!--              {{ lang.messages.errors.inscription[error_message] || 'No has logrado aprobar todas las materias' }}-->
<!--            </h3>-->
            <h2 class="text-xl text-center mb-4">
              <template v-if="student.latest_inscription.semester.number <= 2 && student.latest_inscription.semester.number !== 6">
                Al presionar <b>"Confirmar"</b> el estudiante se inscribirá en <b>{{ student.latest_inscription.specialization.title }}</b> con todas sus materias
              </template>
              <template v-else>
                Al presionar <b>"Confirmar"</b> el estudiante se inscribirá en el <b>semestre {{ student.latest_inscription.semester.number }}</b> de <b>{{ student.latest_inscription.specialization.title }}</b> con las siguientes materias.
              </template>
              <template>

              </template>
              <template v-if="student && !isBasic">
                <h3 class="my-2 font-bold text-red-500">Estas son las materias que el estudiante verá</h3>
                {{ failedCourses.map($0 => $0.label).join(', ') }}
              </template>
            </h2>
          </template>
        </template>
        <div class="flex flex-wrap -mx-2 text-left mt-4">
          <div class="w-1/2 px-2">
            <a-input required label="Fecha" id="payment_date" type="date" v-model="inscription.payment_date" />
          </div>
          <div class="w-1/2 px-2">
            <a-select
            label="Tipo de pago"
            required
            class="mb-4"
            :source="[{label: 'Efectivo', value: 1}, {label: 'Transferencia bancaria', value: 3}, {label: 'Zelle', value: 5}]"
            v-model="inscription.payment_type" />
          </div>
          <div class="w-1/2 px-2">
            <a-input required label="Monto (USD)" id="payment_amount" v-model="inscription.payment_amount" />
          </div>
          <div class="w-1/2 px-2">
            <a-input 
              :required="inscription.payment_type !== 1"
              :disabled="inscription.payment_type === 1"
              label="Número de recibo" 
              id="payment_receipt_number" 
              v-model="inscription.payment_receipt_number" />
          </div>
        </div>
      </div>
    </a-modal>

    <report-inscription-payment-snackbar
      v-if="(!user.isTeacher || !user.isCoordinator) && student"
      :is-admin="user.isAdmin"
      @check="fetch"
      :inscription="student.latest_inscription" />

  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { URL } from '@/constants';
import ReportInscriptionPaymentSnackbar from "@/layouts/Authenticated/componnets/report-inscription-payment-snackbar";
import Vue from "vue";
const token = localStorage.getItem(process.env.VUE_APP_USER_TOKEN_NAME)
export default {
  components: {ReportInscriptionPaymentSnackbar},
  data: () => ({
        url: URL,
        pensumQuery: {
          with: 'specialization,semesters.enabledCourses',
          search: '',
          sort_by: 'name'
        },
        token,
        inscription: {
            specialization_id: null
        },
        changeStudentSpecialization: {
          inscription_ids: [],
          target_specialization_id: null,
          target_pensum_id: null,
          target_semester_id: null
        },
        pensum: [],
        filteredPensum: [],
        filteredSemesters: [],
        semester: [],
        inscriptionModal: false,
        updateEmailModal: false,
        sendEmailModal: false,
        changeSpecializationModal: false,
        retireStudentModal: false,
        alertChangeSpecialization: false,
        disabledUserModal: false,
        enabledPeriod: {
            loading: false,
            period: null,

        },
        loadingSpecializations: false,
        currentSpecialization: [],
        specializations: [],
        nextCourses: [],
        failedCourses: [],
        new_password: '',
        /**
         * @description this attribute is used to know if the
         * user will be inscribed in the next semester or repeat with
         * the reproved courses
         */
        passed: false,
        /**
         * @description this attribute is used to know if the user is
         * specialized or basic
         */
        isBasic: true,
        error_message: null,
        newEmail: '',
        auxPassed: true,
        modals: {
          passwordReset: false
        }
    }),
    computed: {
        ...mapGetters(['lang']),
        ...mapState({
            user: ({session}) => session.user,
            loadingSpecialization: state => state.specializations.loading,
            sections: ({sections}) => sections.all,
            student: ({students}) => students.current,
            pensumsData: state => state.pensums
        }),
        getEnabling() {
          return this.student?.user?.enabled
        },
        // pensum(){
        //   return this.enabledPeriod?.pensums?.find($0 => $0.specialization_id === this.changeStudentSpecialization.specialization_id)
        // },
        // semesters() {
        //   return this.enabledPeriod?.pensums?.find($0=> $0.specialization_id === this.changeStudentSpecialization.specialization_id).semesters ?? []
        // },
        // mappedSemesters() {
        //   console.log(this.semesters);
        //   return this.semesters?.map?.($0 => ({ label: $0.number, value: $0.id }))
        // },
        isConfirmInscriptionButtonDisabled() {
            if (this.passed) {
              if(this.isBasic && this.student.latest_inscription.semester.number < 2){
                const keys = ['payment_date', 'payment_amount', 'payment_type']
                if (this.inscription.payment_type !== 1) {
                    keys.push('payment_receipt_number')
                }
                return keys.some($0 => !this.inscription[$0] || this.inscription[$0] === null || this.inscription[$0] === '')
              }else{
                const keys = ['payment_date', 'payment_amount', 'payment_type', 'specialization_id']
                if (this.inscription.payment_type !== 1) {
                    keys.push('payment_receipt_number')
                }
                return keys.some($0 => !this.inscription[$0] || this.inscription[$0] === null || this.inscription[$0] === '')
              }
            } else {
              const keys = ['payment_date', 'payment_amount', 'payment_type']
                if (this.inscription.payment_type !== 1) {
                    keys.push('payment_receipt_number')
                }
              return keys.some($0 => !this.inscription[$0] || this.inscription[$0] === null || this.inscription[$0] === '')
            }

        },
        isChangeSpecializationButtonDisabled() {
            if(this.changeStudentSpecialization.target_pensum_id === null || this.changeStudentSpecialization.target_semester_id === null || this.changeStudentSpecialization.target_specialization_id === null){
              return false;
            }
            return true;

        },
        shouldDisplaySpecializationSelect() {
            return (this.student?.latest_inscription?.semester?.number ?? 1) !== 1
        },
        shouldDisplayConfirmInscriptionButton() {
            return (this.inscription.specialization_id && this.courses.length) || this.student?.latest_inscription?.semester?.number === 1
        },
        shouldShowInscriptionButton() {
          if (!this.student) return false

          if (this.user.isAdmin && this.enabledPeriod?.period?.enabled_inscriptions) {
              return this.student.latest_inscription.period.id !== this.enabledPeriod.period.id
          }

          return false
        },
        shouldShowChangeSpecializationButton() {
          if(!this.student) return false;
          if (this.user.isAdmin && this.enabledPeriod?.period?.enabled_inscriptions && this.student?.latest_inscription?.semester?.number >= 3) {
            return this.student.latest_inscription.period.id === this.enabledPeriod.period.id;
          }
          return false;
        },
        specializationCourses() {
          return this.specializations.find($0 => $0.value === this.inscription.specialization_id)
            ?.semesters
            ?.find($0 => $0.number === this.student.latest_inscription.semester.number + 1).courses
            // ?.map($0 => $0.courses)
            // ?.flatMap($0 => $0)
            ?.filter(($0, index, self) => self.findIndex($1 => $1.id === $0.id) === index)
            ?.slice(0, 5) ?? []
        },
        mappedSpecializations() {
          return this.specializations.find($0 => $0.value === this.inscription.specialization_id)
        }
    },
    methods: {
        ...mapActions({
            fetchStudent: 'students/show',
            fetchSections: 'sections/fetchAll',
            changeSection: 'students/changeSection',
            fetchStudentByCourseSection: 'students/fetchStudentByCourseSection',
            fetchPensums: 'pensums/index',

        }),
        search() {
            this.fetchPensums({ ...this.pensumQuery })
              ._loading(() => (this.loadingSpecializations = !this.loadingSpecializations))
              .then(({ data: pensums }) => { this.pensum = pensums })
          },
        retireStudent() {
          if (this.student.is_retired) {
            this.$repository.students
                .retireStudent(this.student.id, { retire: false })
                .then(() => {
                  Vue.prototype.$message.success({
                    title: 'Se ha Activado el estudiante',
                  })
                  this.$router.go()
                })
          } else {
            this.$repository.students
                .retireStudent(this.student.id)
                .then(() => {
                  Vue.prototype.$message.success({
                    title: 'Se ha retirado el estudiante',
                  })
                  this.$router.go()
                })
          }
          this.retireStudentModal = false
        },
        resetPassword (student) {
          this.$repository.users
            .updatePassword(student.user_id, { new_password: this.new_password, new_password_confirmation: this.new_password } )
            .then(() => {
              this.$message.success({
                message: 'Se ha actualizado la contraseña correctamente'
              })
            })
            .finally(() => (this.modals.passwordReset = false))
        },
        sendEmail (student) {
            this.$repository.users
              .sendConfirmationEmail(student.user_id)
              .finally(() => (this.sendEmailModal = false))
        },
        disableUser (student) {
            this.$repository.users
                .update(student.user_id, { enabled: !student.user.enabled })
                .then(() => {
                    this.fetch()
                })
                .finally(() => (this.disabledUserModal = false))
        },
        /**
         * @description this parameter indicates if the student will repeat
         * its failed semester
         * @param force_on_fail
         */
        doReinscription(force_on_fail = true) {

            let payload = {
                ...this.inscription,
                payment_amount: (this.inscription.payment_amount ?? 0) * 100,
                force_on_fail
            }

            this.$repository.students
                .inscription(this.student?.id, payload)
                .then(() => {

                    this.$message.success({
                        message: `La inscripción se ha realizado con éxito`,

                    })

                    this.fetch()
                        .then(() => {
                            this.inscriptionModal = false
                        })
                })
        },
        doChangeSpecialization() {
        
          let payload = {
            ...this.changeStudentSpecialization
          }
          this.$repository.inscriptions
              .rollback(payload)
              .then(() => {
                this.$repository.inscriptions
                    .updateSpecialization(payload)
                    .then(() => {
                      this.$message.success({
                        message: `El cambio de especialización fue realizado con éxito`,

                      })
                      this.fetch()
                          .then(() => {
                              this.changeStudentSpecialization.target_pensum_id = null;
                              this.changeStudentSpecialization.target_semester_id = null;
                              this.changeStudentSpecialization.target_specialization_id = null;
                              this.changeSpecializationModal = false;
                              this.alertChangeSpecialization = true;
                          })
                    })
              })
        },
        changeSpecialization() { 
          const filteredInscriptions = this.student.inscriptions.filter($0 => $0.period_id === this.student.latest_inscription.period_id)
          this.changeStudentSpecialization.inscription_ids = filteredInscriptions.map(($0 => $0.id));

          this.changeSpecializationModal = true
          this.$repository.specializations.index({ limit: 0 })
            ._loading(() => (this.loadingSpecializations = !this.loadingSpecializations))
            .then(({ data: specializations }) => {
              this.specializations = specializations.filter($0 => $0.id >= 3 && $0.id != this.student.latest_inscription.specialization_id).map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
              this.search();         
            })
          this.$repository.semesters.index({ limit: 0})
            ._loading(() => (this.loadingSpecializations = !this.loadingSpecializations))
            .then(({ data: semester }) => {
              this.semester = semester
            })

        },
        reinscript() {
          this.$repository.specializations.index({ limit: 0 })
            ._loading(() => (this.loadingSpecializations = !this.loadingSpecializations))
            .then(({ data: specializations }) => {
              this.specializations = specializations.filter($0 => $0.id !== 1).map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
            })

          this.$repository.students
            .verifySemesterApproval(this.student?.id)
            .then((response) => {
              this.isBasic = response.data.is_basic
              this.passed = response.data.passed
              this.error_message = response.data.message
              this.currentSpecialization = response.data.current_specialization
              // if(this.isBasic){
              //   this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.course.title, value: $0.course.id }))
              //   console.log(this.failedCourses);
              // }else{ this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.title, value: $0.id })) }

              if (response.data.passed) {
                if (this.student.latest_inscription.semester.number === 2 ) {
                  this.inscriptionModal = true

                  this.$repository.specializations.index({ limit: 0, with: 'semesters.courses' })
                    ._loading(() => (this.loadingSpecializations = !this.loadingSpecializations))
                    .then(({ data: specializations }) => {
                      this.specializations = specializations.filter($0 => $0.id !== 1).map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
                    })
                } else if (this.student.latest_inscription.semester.number === 6) {
                  this.inscriptionModal = false

                  this.$message.warning({
                      title: 'No se puede inscribir',
                      message: 'Este estudiante ha aprobado el último semestre'
                    })

                } 
                else { 
                  if (this.isBasic === false) { 
                    this.inscriptionModal = true
                    
                    this.inscription.specialization_id = response.data.current_specialization.id
                    this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.title, value: $0.id }))
                    // if(this.failedCourses.length != 0)
                    // this.inscriptionModal = true
                    this.nextCourses = response.data.details.next_semester_courses
                }
                  this.inscriptionModal = true

                  this.$repository.students
                    .nextSemester(this.student?.id)
                    .then(({ data }) => {
                      this.inscriptionModal = true
                      this.inscription.specialization_id = data.specialization_id
                      if(this.failedCourses.length != 0 ){
                        this.nextCourses = data.details.next_semester_courses
                      } else{ this.nextCourses = data.courses }
                    })
                }
              } else {
                this.passed = false
                this.inscriptionModal = true
                this.inscription.force_on_fail = true
                if(this.isBasic){
                  this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.course.title, value: $0.course.id }))
                }else{
                  this.failedCourses = response?.data?.details?.failed_course_sections?.map($0 => ({ label: $0.title, value: $0.id }))
                }
              }

              // return { data.current_specialization.specialization, data.current_semester.semester }
            })
            // .then(() => {
            //     if (this.student.latest_inscription?.semester?.number === 1) {
            //         this.$repository.students
            //             .nextSemester(this.student?.id)
            //             .then(({ data }) => {
            //                 this.inscriptionModal = true
            //                 this.inscription.specialization_id = data.specialization_id
            //                 this.courses = data.courses
            //             })
            //     } else {
            //
            //         this.inscriptionModal = true
            //
            //         this.loadingSpecializations = !this.loadingSpecializations
            //         this.$repository.specializations
            //             .index({ limit: 0, with: 'semesters.courses' })
            //             .then(({ data }) => {
            //                 this.specializations = data.map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
            //             })
            //             .finally(() => this.loadingSpecializations = !this.loadingSpecializations)
            //     }
            // })

            // .then(({ specialization }) => {
            //   this.inscription.specialization_id = specialization.id
            //
            //   if (this.student.latest_inscription?.semester?.number === 1) {
            //     this.$repository.students
            //       .nextSemester(this.student?.id)
            //       .then(({ data }) => {
            //         this.inscription.specialization_id = data.specialization_id
            //         this.courses = data.courses
            //       })
            //   }
            //
            //   this.$repository.specializations.index({ limit: 0, with: 'semesters.courses' })
            //     .then(({ data: specializations }) => {
            //       this.specializations = specializations.filter($0 => $0.id !== 1).map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
            //     })
            // })
            .catch((error) => {
              if (error?.response?.data?.error_code === 'EIN001' ) {
                this.passed = false
                this.inscriptionModal = true
                this.inscription.force_on_fail = true
              }
            })

            // this.$message.loading({
            //     message: 'Verificando datos',
            //     position: 'top-right',
            //     asyncAction: ({ close }) => {
            //       this.$repository.students
            //             .verifySemesterApproval(this.student?.id)
            //             // .then(res => {
            //             //     this.isBasic = res.data.is_basic
            //             //     this.passed = res.data.passed
            //             // })
            //             // .then(({ data: { is_basic, passed, message, current_specialization: specialization, current_semester: semester } }) => {
            //             .then(({ response: data }) => {
            //               this.isBasic = data.is_basic
            //               this.passed = data.passed
            //               this.error_message = data.message
            //               this.inscriptionModal = true
            //               console.log('dqdqdq')
            //               // return { data.current_specialization.specialization, data.current_semester.semester }
            //             })
            //
            //             .then(() => {
            //               if (this.passed) {
            //                 if (this.student.latest_inscription.semester.number !== 2) {
            //                   this.$repository.students
            //                     .nextSemester(this.student?.id)
            //                     .then(({ data }) => {
            //                       this.inscriptionModal = true
            //                       this.inscription.specialization_id = data.specialization_id
            //                       this.courses = data.courses
            //                       console.log(data)
            //                     })
            //                 } else {
            //                   this.$repository.specializations.index({ limit: 0, with: 'semesters.courses' })
            //                     .then(({ data: specializations }) => {
            //                       this.specializations = specializations.filter($0 => $0.id !== 1).map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
            //                     })
            //                 }
            //               }
            //             })
            //             // .then(() => {
            //             //     if (this.student.latest_inscription?.semester?.number === 1) {
            //             //         this.$repository.students
            //             //             .nextSemester(this.student?.id)
            //             //             .then(({ data }) => {
            //             //                 this.inscriptionModal = true
            //             //                 this.inscription.specialization_id = data.specialization_id
            //             //                 this.courses = data.courses
            //             //             })
            //             //     } else {
            //             //
            //             //         this.inscriptionModal = true
            //             //
            //             //         this.loadingSpecializations = !this.loadingSpecializations
            //             //         this.$repository.specializations
            //             //             .index({ limit: 0, with: 'semesters.courses' })
            //             //             .then(({ data }) => {
            //             //                 this.specializations = data.map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
            //             //             })
            //             //             .finally(() => this.loadingSpecializations = !this.loadingSpecializations)
            //             //     }
            //             // })
            //
            //             // .then(({ specialization }) => {
            //             //   this.inscription.specialization_id = specialization.id
            //             //
            //             //   if (this.student.latest_inscription?.semester?.number === 1) {
            //             //     this.$repository.students
            //             //       .nextSemester(this.student?.id)
            //             //       .then(({ data }) => {
            //             //         this.inscription.specialization_id = data.specialization_id
            //             //         this.courses = data.courses
            //             //       })
            //             //   }
            //             //
            //             //   this.$repository.specializations.index({ limit: 0, with: 'semesters.courses' })
            //             //     .then(({ data: specializations }) => {
            //             //       this.specializations = specializations.filter($0 => $0.id !== 1).map($0 => ({ label: $0.title, value: $0.id, semesters: $0.semesters }))
            //             //     })
            //             // })
            //             .catch((error) => {
            //                 if (error?.response?.data?.error_code === 'EIN001' ) {
            //                   this.passed = false
            //                   this.inscriptionModal = true
            //                   this.inscription.force_on_fail = true
            //                 }
            //             })
            //             .finally(() => {
            //                 close()
            //             })
            //     }
            // })
        },
        ...mapMutations({
            setStudent: 'students/SET'
        }),
        studentsLink(name = '') {
            if (!this.student) {
                return { name }
            }

            if (!this.user.isAdmin && !this.user.isLowAdmin && !this.user.isCoordinator) {
                name = `section_course_student${name ? `_${name}` : ''}`
            } else {
                name = `student${name ? `_${name}` : ''}`
            }
            let baseRoute = { name, params: { student: this.student?.id } }

            if (!this.user.isAdmin) {
                baseRoute.params = {
                    ...this.$route.params,
                    ...baseRoute.params
                }
            }

            return baseRoute
        },
        updateEmail () {
          this.$repository.users
            .updateEmail(this.student.user_id, { new_email: this.newEmail })
            .then(() => (this.fetch()))
            .catch((err) => (console.log(err)))
            .finally(() => {
              this.updateEmailModal = false
            })
        },
        fetch() {
            this.enabledPeriod.loading = !this.enabledPeriod.loading

            this.$repository.periods
              .enabled({
                with: 'pensums.specialization,pensums.semesters.sections.courses'
              })
              .then(res => {
                  this.enabledPeriod.period = res.data
              })
              .finally(() => this.enabledPeriod.loading = !this.enabledPeriod.loading)
            if (this.user.isAdmin || this.user.isLowAdmin || this.user.isCoordinator) {
                const { student } = this.$route.params

                return this.fetchStudent({
                    id: student,
                    query: {
                        with: `latestInscription.period,latestInscription.specialization,latestInscription.semester.courses,latestInscription.section,user,inscriptions.semester`
                    }})
            } else {
                const { section, course, student } = this.$route.params

                return this.fetchStudentByCourseSection({section, course, student, filters: {
                        with: `latestInscription.period,latestInscription.specialization,latestInscription.semester,latestInscription.section,user`
                    }})
            }
        },
    },
    mounted() {
       this.fetch()
    },
    watch: {
        'inscription.specialization_id'(val) {
            if (val && val !== 1) {
                this.courses = this.specializations.find($0 => $0.value === val)
                    ?.semesters
                    .find($0 => $0.number === (this.student?.latest_inscription?.semester?.number ?? 0) + 1)?.courses ?? []
            }    
        },
        'inscription.payment_type'(val) {
            if(val === 1 ) { this.inscription.payment_receipt_number = null }
          },
        updateEmailModal () {
          this.newEmail = this.student.email
        },
        'changeStudentSpecialization.target_specialization_id'(val) {
          if(!val){
            this.changeStudentSpecialization.target_semester_id = null
            this.changeStudentSpecialization.target_pensum_id = null

          } else{
            this.changeStudentSpecialization.target_semester_id = null
            this.changeStudentSpecialization.target_pensum_id = null
            const pensumFilter = this.pensum.filter($0 => $0.specialization_id === val)
            this.filteredPensum = pensumFilter.map(($0) => ({ label: $0.name, value: $0.id, semesters: $0.semesters })).sort((a, b) => a.value - b.value);
          }
        },
        'changeStudentSpecialization.target_pensum_id'(val) {
          this.changeStudentSpecialization.target_semester_id = null;
          const semesterFilter = this.semester.filter($0 => $0.pensum_id === val);
          this.filteredSemesters = semesterFilter.map(($0) => ({ label: `[${$0.number}] - ${$0.code}`, value: $0.id })).sort((a, b) => a.label - b.label);

        }
        // 'changeStudentSpecialization.target_semester_id'(val) { 
        //   if(val){
        //     const semesterFilter = this.enabledPeriod.period.pensums.find($0=> $0.specialization_id === this.changeStudentSpecialization.target_specialization_id);
        //     const pensumFilter = this.pensum.find($0 => $0.id === val)

        //     this.changeStudentSpecialization.target_pensum_id = pensumFilter.pensum_id
            
        //   } else {
        //     this.changeStudentSpecialization.target_pensum_id = null;
        //   }
        // }
    }
}
</script>
